import React from "react";
import AboutCompany from "../components/aboutUs/aboutCompany";
import CeoCart from "../components/aboutUs/ceoCart";
import OurValue from "../components/aboutUs/ourValue";
import { primaryBlockDataHandler } from "../helper/api/primaryBlock.api";
import Layout from "../components/layout";
import LeftCurve from "../components/svg/leftCurve";
import RightCurve from "../components/svg/rightCurve";
import OurValueRightSingleCurve from "../components/svg/aboutUsPage/ourValueRightSingleCurve";
import SeoDataHandler from "../helper/handler/seoData.handler";
import { SEO } from "../components/seo";

const AboutUs: React.FC<any> = ({ pageContext }) => {
  const companyValueData = primaryBlockDataHandler(
    pageContext?.primaryBlockData
  );

  return (
    <Layout
      footerData={pageContext.common.footerData}
      navbarData={pageContext.common.navbarData}
    >
      <div>
        <div className={"-z-1 absolute left-0 hidden hidden lg:block"}>
          <LeftCurve />
        </div>
        <div className={"-z-1 absolute right-0 hidden sm:hidden lg:block"}>
          <RightCurve />
        </div>
        <AboutCompany
          data={pageContext?.pageData?.data}
          meta={pageContext?.pageData?.meta}
        />
        <div
          className={
            "flex w-full flex-col items-center justify-center px-[50px]"
          }
        >
          <div
            className={
              "-z-1 absolute right-0 hidden sm:hidden md:block lg:block"
            }
          >
            <OurValueRightSingleCurve />
          </div>
          <div
            className={"relative z-20 z-[3] flex justify-center"}
            style={{ marginTop: "-32px" }}
          >
            <CeoCart cartData={pageContext?.ceoData} />
          </div>
          <OurValue companyValueData={companyValueData} />
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;

export const Head: React.FC<any> = ({ pageContext }) => {
  const seoData = SeoDataHandler(pageContext?.pageData);
  return (
    <SEO
      title={seoData.title}
      description={seoData.description}
      keywords={seoData.keywords}
      image={seoData.image}
      pathname={seoData.pagePath}
    />
  );
};
