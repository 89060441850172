import React from "react";
import MaxWidthWrapper from "../common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";

interface ceoCartProps {
  cartData: {
    name?: string;
    position?: string;
    linkedIn?: string;
    imageData?: {
      altText: string;
      url: string;
      media: string;
    };
    biography1?: string;
    biography2?: string;
  };
}

const CeoCart: React.FC<ceoCartProps> = ({ cartData }) => {
  return (
    <MaxWidthWrapper>
      <SecondaryWidthWrapper>
        {/*For Screen which is less than LG*/}
        <div className={"m-auto md:w-[80%]"}>
          <div
            className={
              "m-auto block flex w-[85%] flex-col gap-[24px] rounded-[7px] py-[40px] lg:hidden"
            }
            style={{
              background:
                "linear-gradient(180deg, #F5FDF6 54.25%, #FFFFFF 100%)",
            }}
          >
            <div className={"flex flex-col items-center gap-y-4"}>
              <div className={"flex flex-col items-center text-lg font-medium"}>
                <h2 className={"text-black-gray"}>
                  About the{" "}
                  <span className={"text-primary"}>{cartData?.position}</span>
                </h2>
                <p className={"text-primary underline"}>
                  <a href={"#"}>{cartData?.name}</a>
                </p>
              </div>
              <img
                className={"w-1/4 rounded-lg object-cover object-center"}
                src={cartData?.imageData?.media}
                alt={cartData?.imageData?.altText}
              />
            </div>
            <div
              className={
                "flex flex-col gap-y-4 px-[18px] text-[14px] text-black-400"
              }
            >
              <p className={"text-center"}>{cartData?.biography1}</p>
              <p className={"text-center"}>{cartData?.biography2}</p>
            </div>
          </div>
        </div>
        {/*large screen cart*/}
        <div
          className={
            "m-auto hidden w-[80%] gap-[20px] rounded-[13px] py-[80px] px-[70px] lg:flex lg:px-[50px] lg:pb-[167px] xl:px-[70px]"
          }
          style={{
            background: "linear-gradient(180deg, #F5FDF6 54.25%, #FFFFFF 100%)",
          }}
        >
          <img
            className={
              "rounded-xl object-cover object-center lg:h-4/5 lg:w-2/4 xl:w-full"
            }
            src={cartData?.imageData?.media}
            alt={cartData?.imageData?.altText}
            style={{
              height: "640px",
            }}
          />
          <div className={"lg:flex lg:flex-col lg:items-start lg:gap-y-12"}>
            <div className={"lg:flex lg:flex-col lg:gap-y-10 xl:gap-y-12"}>
              <h2
                className={
                  "lg:text-[26px] lg:font-semibold lg:text-black xl:text-4xl"
                }
              >
                About the&nbsp;
                <span className={"text-primary"}>{cartData?.position}</span>
              </h2>
              <p
                className={
                  "underline-offset-6 underline lg:text-[20px] lg:font-medium lg:text-primary xl:text-3xl"
                }
              >
                <a href={cartData?.linkedIn}>{cartData?.name}</a>
              </p>
            </div>
            <div
              className={
                "text-black-400 lg:flex lg:flex-col lg:gap-y-7 lg:text-[18px] xl:text-[21px]"
              }
            >
              <p>{cartData?.biography1}</p>
              <p>{cartData?.biography2}</p>
            </div>
          </div>
        </div>
      </SecondaryWidthWrapper>
    </MaxWidthWrapper>
  );
};

export default CeoCart;
