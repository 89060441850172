import React from "react";
import HeadingDescription from "../common/headingDescription";
import PageType from "../../types/page.type";
import MaxWidthWrapper from "../../components/common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../../components/common/SecondaryWidthWrapper";

const AboutCompany: React.FC<PageType> = (props) => {
  return (
    <div
      className={
        "bg-gradient-to-b from-hero-gradient-start to-hero-gradient-end xl:px-0"
      }
    >
      <MaxWidthWrapper>
        <SecondaryWidthWrapper>
          <div
            className={
              "relative flex flex-col items-center justify-center pt-[40px] "
            }
          >
            {/*about us*/}
            <div className={"flex flex-col items-center gap-y-8"}>
              <div className={"xl:w-3/5"}>
                <HeadingDescription
                  blackHeading={
                    props.data?.[0]?.attributes.coverSection.title.primaryText
                  }
                  greenHeading={
                    props.data?.[0]?.attributes.coverSection.title.secondaryText
                  }
                  blackDescription={
                    props.data?.[0]?.attributes.coverSection.description
                      .primaryText
                  }
                  textAlignment={"text-center"}
                  textColor={"#111111"}
                  descriptionWidth={"w-4/5"}
                />
              </div>
              <div>
                <img
                  className={"object-fill object-center"}
                  src={`${props.data?.[0]?.attributes.coverSection.imageData.media.data[0].attributes.url}`}
                  alt={
                    props.data?.[0]?.attributes.coverSection.imageData.altText
                  }
                />
              </div>
            </div>
          </div>
        </SecondaryWidthWrapper>
      </MaxWidthWrapper>
    </div>
  );
};

export default AboutCompany;
