import axios from "axios";
import PrimaryBlockType, {
  primaryBlockDataType,
} from "../../types/PrimaryBlock.type";

const PrimaryBlockApi = async (slug: string) => {
  try {
    const axiosResponse = await axios.get(
      `${process.env.GATSBY_API_URL}/primary-blocks`,
      {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_API_KEY}`,
        },
        params: {
          populate: ["titleSection", "dataSection.imageData.media", "button"],
          "filters[slug][$eq]": slug,
        },
      }
    );
    return axiosResponse.data;
  } catch (error) {
    console.log(error);
  }
};

export const primaryBlockDataHandler = (
  primaryBlockData: PrimaryBlockType | undefined
) => {
  const primaryBlock: primaryBlockDataType = {
    titleSection: {
      primaryTitle: primaryBlockData?.data?.[0]?.attributes?.titleSection
        ?.primaryTitle
        ? primaryBlockData?.data?.[0]?.attributes?.titleSection?.primaryTitle
        : "",
      primaryDescription: primaryBlockData?.data?.[0]?.attributes?.titleSection
        ?.primaryDescription
        ? primaryBlockData?.data?.[0]?.attributes?.titleSection
            ?.primaryDescription
        : "",
      secondaryTitle: primaryBlockData?.data?.[0]?.attributes?.titleSection
        ?.secondaryTitle
        ? primaryBlockData?.data?.[0]?.attributes?.titleSection?.secondaryTitle
        : "",
      secondaryDescription: primaryBlockData?.data?.[0]?.attributes
        ?.titleSection?.secondaryTitle
        ? primaryBlockData?.data?.[0]?.attributes?.titleSection
            ?.secondaryDescription
        : "",
    },
    dataSection: [
      {
        title: "",
        description: "",
        imageData: {
          url: "",
          altText: "",
          media: "",
        },
        button: {
          text: "",
          url: "",
        },
        order: 0,
      },
    ],
    button: {
      text: primaryBlockData?.data?.[0]?.attributes?.button?.text
        ? primaryBlockData?.data?.[0]?.attributes?.button?.text
        : "",
      url: primaryBlockData?.data?.[0]?.attributes?.button?.url
        ? primaryBlockData?.data?.[0]?.attributes?.button?.url
        : "",
    },
  };

  primaryBlockData?.data?.[0]?.attributes?.dataSection?.forEach(
    (dataSectionItem, index) => {
      if (index === 0) {
        primaryBlock.dataSection.pop();
      }
      let dataItem = {
        title: dataSectionItem?.title ? dataSectionItem?.title : "",
        description: dataSectionItem?.description
          ? dataSectionItem?.description
          : "",
        order: dataSectionItem?.order ? dataSectionItem?.order : 0,
        imageData: {
          altText: dataSectionItem?.imageData?.altText
            ? dataSectionItem?.imageData?.altText
            : "",
          url: dataSectionItem?.imageData?.url
            ? dataSectionItem?.imageData?.url
            : "",
          media: dataSectionItem?.imageData?.media?.data?.[0]?.attributes?.url
            ? `${dataSectionItem?.imageData?.media?.data?.[0]?.attributes?.url}`
            : "",
        },
        button: {
          text: dataSectionItem?.button?.text
            ? dataSectionItem?.button?.text
            : "",
          url: dataSectionItem?.button?.url ? dataSectionItem?.button?.url : "",
        },
      };
      primaryBlock.dataSection.push(dataItem);
    }
  );
  return primaryBlock;
};
export default PrimaryBlockApi;
