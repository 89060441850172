import React from "react";

export interface HeadingDescriptionTextProps {
  blackHeading: string;
  greenHeading: string;
  blackDescription?: string;
  greenDescription?: string;
  textAlignment?: string;
  gap?: number;
  fontWeight?: string;
  textColor?: string;
  descriptionWidth?: string;
}

const HeadingDescription: React.FC<HeadingDescriptionTextProps> = (props) => {
  return (
    <div
      className={`flex flex-col items-center gap-y-[15px] 
      md:gap-y-[22px] 
      lg:gap-y-[25px] 
      xl:flex xl:flex-col xl:gap-y-[32px]
      2xl:flex 2xl:flex-col 2xl:gap-y-[32px]`}
    >
      <div>
        <h2
          className={
            `text-[18px] font-medium text-[#111111] md:text-[22px] ${props.textAlignment} ` +
            "md:font-semibold " +
            "lg:text-[26px] lg:font-semibold lg:text-black " +
            "xl:text-[35px] xl:font-semibold "
          }
        >
          {props.blackHeading}&nbsp;
          <span className={"text-primary"}>{props.greenHeading}</span>
        </h2>
      </div>
      <div
        className={`flex w-9/12 flex-col ${props.textAlignment} text-[14px] md:w-4/5 lg:w-3/5 lg:text-[17px] xl:${props.descriptionWidth} xl:text-[25px]`}
      >
        <p
          className={`leading-5 tracking-tight text-[${props.textColor}] text-[#111111] md:leading-5 lg:leading-7 xl:leading-9 xl:tracking-wide`}
        >
          {props.blackDescription}
        </p>
        <p className={"text-tertiary"}>{props.greenDescription}</p>
      </div>
    </div>
  );
};

export default HeadingDescription;
