import React from "react";

interface companyValueItemProps {
  dataSection: {
    title: string;
    description: string;
    order: number;
    imageData: {
      altText: string;
      url: string;
      media: string;
    };
    button: {
      text: string;
      url: string;
    };
  };
  drawLine: boolean;
}

const OurValueItem: React.FC<companyValueItemProps> = ({
  dataSection,
  drawLine,
}) => {
  return (
    <div className={"flex flex-col items-end gap-y-14 pb-8 lg:gap-y-12"}>
      <div className={"flex gap-x-5 lg:gap-x-[73px]"}>
        {/*company value icons*/}
        <div
          className={
            "flex w-1/5 gap-x-3.5 lg:w-1/5 lg:w-1/5 lg:gap-x-11 xl:w-44"
          }
        >
          <div className={"border-2 border-primary bg-primary lg:border-[5px]"}>
            {" "}
          </div>
          <div className={"lg:flex"}>
            <img
              className={"md:w-3/5 lg:w-full xl:w-full"}
              src={dataSection.imageData.media}
              alt={dataSection.imageData.altText}
            />
          </div>
        </div>
        {/*company value description*/}
        <div className={"flex  w-4/5 flex-col gap-y-1.5 lg:w-full"}>
          <div>
            <h3
              className={
                " text-[17px] text-tertiary lg:text-[23px]  lg:font-medium"
              }
            >
              {dataSection.title}
            </h3>
          </div>
          <div>
            <p
              className={
                "text-[14px] font-light text-gray-200 lg:text-[18px] xl:text-[19px]"
              }
            >
              {dataSection.description}
            </p>
          </div>
        </div>
      </div>
      {drawLine && (
        <div className={"w-[90%] border border-green-100 bg-green-100"}></div>
      )}
    </div>
  );
};

export default OurValueItem;
