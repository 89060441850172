import React from "react";

function OurValueRightSingleCurve() {
  return (
    <svg
      className={"w-[200px] xl:w-[341px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="341"
      height="1500"
      fill="none"
      viewBox="0 0 341 1500"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.6"
        d="M340.001.5C-7.999 210-78.499 704 85.486 1121.71 217.09 1390 340 1499.5 340 1499.5"
      ></path>
    </svg>
  );
}

export default OurValueRightSingleCurve;
