import React from "react";
import HeadingDescription from "../common/headingDescription";
import OurValueItem from "./ourValue/common/ourValueItem";
import { primaryBlockDataType } from "../../types/PrimaryBlock.type";
import MaxWidthWrapper from "../../components/common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../../components/common/SecondaryWidthWrapper";

interface ourValueProps {
  companyValueData: primaryBlockDataType;
}

const OurValue: React.FC<ourValueProps> = ({ companyValueData }) => {
  return (
    <MaxWidthWrapper>
      <SecondaryWidthWrapper>
        <div className={"pt-[60px] pb-16 lg:pb-48 lg:pt-0"}>
          <div className={"pb-10"}>
            <HeadingDescription
              blackHeading={companyValueData.titleSection.primaryTitle}
              greenHeading={companyValueData.titleSection.secondaryTitle}
            />
          </div>
          <div>
            {companyValueData.dataSection.map((item) => (
              <OurValueItem
                key={item.order}
                dataSection={item}
                drawLine={
                  item.order !==
                  companyValueData.dataSection[
                    companyValueData.dataSection.length - 1
                  ]?.order
                }
              />
            ))}
          </div>
        </div>
      </SecondaryWidthWrapper>
    </MaxWidthWrapper>
  );
};

export default OurValue;
