import React from "react";

function LeftCurve() {
  return (
    <svg
      className={"w-[300px] xl:w-[474px]"}
      xmlns="http://www.w3.org/2000/svg"
      height="925"
      fill="none"
      viewBox="0 0 474 925"
    >
      <path
        fill="#B0C5B2"
        fillOpacity="0.35"
        fillRule="evenodd"
        d="M0 924.469c39.615.872 79.44-6.345 115.482-17.505C309.77 847.346 467.736 667.875 473.448 469.22 479.86 246.99 268.436 41.619 31.38 5.053A809.563 809.563 0 000 .873v1.006a808.478 808.478 0 0131.227 4.162C149.53 24.29 261.455 84.665 342.999 168.211c81.545 83.547 132.646 190.197 129.449 300.98-5.697 198.154-163.308 377.303-357.26 436.816l-.002.001C79.198 917.152 39.478 924.342 0 923.469v1zm0-98.435a377.385 377.385 0 0025.644-6.97c172.637-52.975 313.081-212.46 318.161-389.134 2.853-98.872-42.752-193.925-115.336-268.292C167.273 98.94 86.857 50.898 0 27.628v2.07c86.298 23.226 166.204 71.009 227.038 133.337 72.301 74.076 117.602 168.629 114.768 266.838-5.051 175.674-144.786 334.512-316.75 387.279l-.003.001A375.494 375.494 0 010 823.98v2.054z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LeftCurve;
